import { Component, Vue } from 'vue-property-decorator'
import { Products } from '@/domain/value-objects/Products'
import SelectInput from '@/components/inputs/select/SelectInput.vue'

@Component({
  components: {
    SelectInput
  }
})
export default class Header extends Vue {
  private products = Object.keys(Products).reduce(
    (acc: any, currValue: string) => {
      acc[currValue] = Products[currValue].name
      return acc
    },
    {}
  )

  get disableProductSelector () {
    return this.$route.name !== 'IndexTemplate' && this.$route.name !== 'TriggerIndex'
  }

  get currentProduct () {
    return this.$store.state.product
  }

  private hamburger () {
    this.$store.commit('hamburgerSwitch')
  }

  setProduct (product: string) {
    this.$store.commit('setProduct', { product: product })
  }
}
