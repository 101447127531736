import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class SelectInput extends Vue {
  private value: string

  @Prop({ required: false, type: String })
  readonly initialValue: string

  @Prop({ required: true, type: String })
  readonly label: string

  @Prop({ required: true, type: Object })
  readonly options: object

  @Prop({ required: false, type: Boolean })
  readonly disabled: boolean

  get actualValue () {
    return this.value ? this.value : this.$props.initialValue
  }

  set actualValue (value: string) {
    this.value = value
    this.$emit('change', value)
  }

  private created () {
    if (!this.initialValue) {
      this.actualValue = this.$props.options[Object.keys(this.$props.options)[0]]
    }
  }
}
